
let user = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")).user
    : "";
let token = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")).token
    : "";
let language = localStorage.getItem("language")
    ? JSON.parse(localStorage.getItem("language")) : "nl";

let surveysCompleted = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")).surveysCompleted : false

export const initialState = {
    userDetails: user,
    token: "" || token,
    language: language,
    surveysCompleted: surveysCompleted
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                userDetails: action.payload.user,
                token: action.payload.token,
                surveysCompleted: action.payload.complete,
            };
        case "LOGOUT":
            return {
                ...initialState,
                userDetails: "",
                token: ""
            };
        case "LANG_CHANGE":
            return {
                ...initialState,
                language: action.language
            }
        case "PROFILE_CHANGED":
            return {
                ...initialState,
                userDetails: action.user
            }
        case "PROGRESS_CHANGED":
            return {
                ...initialState,
                surveysCompleted: action.complete
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
