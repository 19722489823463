
import { useState, useEffect } from 'react'
import { ReactComponent as Leaf } from "../assets/images/frunza.svg"
import { ReactComponent as Check } from "../assets/images/check.svg"
import { ReactComponent as NavbarLogo1 } from "../assets/images/navbar-logo-1.svg"
import { ReactComponent as NavbarLogo2 } from "../assets/images/navbar-logo-2.svg"
import { ReactComponent as NavbarLogo3 } from "../assets/images/navbar-logo-3.svg"
import { ReactComponent as Exit } from "../assets/images/exit-1.svg"
import { useNavigate } from 'react-router-dom';
import { useAuthDispatch, logoutUser, useAuthState } from '../context'
import lang from '../lang'

const Sidebar = () => {
    const navigate = useNavigate()
    const dispatch = useAuthDispatch()
    const { userDetails, surveysCompleted } = useAuthState()
    const [profileProgress, setProfileProgress] = useState(false)

    const handleLogout = () => {
        logoutUser(dispatch)

        navigate('/login')
        window.location.reload();
    }

    useEffect(() => {
        let step1 = false;
        if (
            userDetails.gender &&
            userDetails.age &&
            userDetails.education &&
            userDetails.education !== "0" &&
            userDetails.cultural_background &&
            userDetails.cultural_background !== "0" &&
            userDetails.questions.length
        ) {
            step1 = true;
        }
        setProfileProgress(step1)

    }, [userDetails]);


    return (
        <div className="left-navbar">
            <div className="left-navbar_title">
                <Leaf />
                <h4>{lang.get('welcome')}!</h4>
            </div>
            <div className="left-navbar_linie"></div>

            <div onClick={() => navigate('/profile')} className={`left-navbar_container ${window.location.href.indexOf('profile') != -1 ? 'is-active' : ''}`}>
                <div className="nav-bar_item">
                    <NavbarLogo1 />
                    <p>{lang.get('your-profile')}</p>
                </div>
                <div className={`check-container ${profileProgress ? 'is-complete' : ''}`}>
                    <p>1</p>
                    <Check />
                </div>
            </div>

            <div onClick={() => profileProgress ? navigate('/questionnaires') : null} className={`left-navbar_container ${window.location.href.indexOf('questionnaires') != -1 ? 'is-active' : ''} ${!profileProgress ? 'inactive' : ''}`}>
                <div className="nav-bar_item">
                    <NavbarLogo2 />
                    <p>{lang.get('your-questionnaires')}</p>
                </div>
                <div className={`check-container ${surveysCompleted ? 'is-complete' : ''}`}>
                    <p>2</p>
                    <Check />
                </div>
            </div>
            <div onClick={() => surveysCompleted ? navigate('/report') : null} className={`left-navbar_container last ${window.location.href.indexOf('report') != -1 ? 'is-active' : ''} ${!surveysCompleted ? 'inactive' : ''}`}>
                <div className="nav-bar_item">
                    <NavbarLogo3 />
                    <p>{lang.get('your-report')}</p>
                </div>
                <div className={`check-container ${profileProgress.step3 ? 'is-complete' : ''}`}>
                    <p>3</p>
                    <Check />
                </div>
            </div>

            <div className="left-navbar_logout">
                <p className="left-navbar_logout-p">{lang.get('save-progress')}</p>

                <button className="logout-btn" onClick={handleLogout}>
                    <p>{lang.get('logout')}</p>
                    <Exit />
                </button>
            </div>
        </div>

    );
}

export default Sidebar;