import Questionnaire from "../components/Questionnaire"
import { useNavigate } from "react-router-dom"
import Sidebar from "../components/Sidebar"
import { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Button from "../components/Button"
import Errors from "../components/Errors"
import { useAuthState } from '../context'
import api from "../api"
import lang from "../lang"


const Questionnaires = () => {
    const { language, userDetails, surveysCompleted } = useAuthState()
    const [groups, setGroups] = useState(false)
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (userDetails) {
            api.get('questionnaires').then(response => {
                if (response.data) {
                    setGroups(response.data)
                }
            });
        }
    }, []);

    const goToReport = () => {
        if (surveysCompleted) {
            navigate('/report')
        } else {
            setError(lang.get('complete-all-surveys'))

            setTimeout(() => {
                setError(false)
            }, 4000)
        }
    }


    const hideError = () => {
        setError(false)
    }

    return (
        <Layout header={true}>
            <div className="container">
                <div className="page-content">
                    <Sidebar />
                    <div className="content">
                        {groups && groups.map((category, index) => (
                            <div key={`QuestCat` + index}>
                                <h3 className="questionnaires-title">{category.title[language]}</h3>
                                {category.questionnaires.map((ques, index2) => (
                                    <Questionnaire key={`questionnaire` + index2} questionnaire={ques} number={index2 + 1} />
                                ))}
                            </div>
                        ))}
                        <div>
                            <Button type="button" text={lang.get('next')} className="questionnaires-btn" color={surveysCompleted ? '#970815' : ''} onClick={goToReport}></Button>
                            {error &&
                                <div className="error inline-error">
                                    <div>ERROR</div>
                                    {error}
                                    <span className="hide" onClick={() => hideError()}>&#215;</span>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default Questionnaires