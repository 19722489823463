import mask1 from '../assets/images/MaskGroup1.png'
import mask2 from '../assets/images/MaskGroup2.png'
import mask3 from '../assets/images/MaskGroup3.png'

export default function AccountGroupImages() {
    return (
        <div className="form-container_img">
            <img src={mask1} alt="Mask 1" />
            <img src={mask2} alt="Mask 2" />
            <img src={mask3} alt="Mask 3" />
        </div>
    );
}