import axios from "axios";

const API_URL = process.env.REACT_APP_PUBLIC_API
axios.defaults.withCredentials = true;

let auth = localStorage.getItem('auth');
let token = false;
const api = {}

if (auth) {
    auth = JSON.parse(auth);
    token = 'Bearer ' + auth.token;
}

api.post = (url, data) => {
    return api.request("POST", url, data);
};

api.get = (url, data, optionals) => {
    return api.request("GET", url, data, optionals);
};
// Prepare a request to send
api.request = (method, url, data, optionals) => {
    return new Promise((resolve, reject) => {
        method = method.toUpperCase();
        url = API_URL + url;
        let headers = {};
        headers["Content-Type"] = "application/json";

        if (token) {
            headers['Authorization'] = token;
        }

        api.sendRequest(method, url, headers, data, optionals)
            .then((response) => {
                resolve(response);
            })
            .catch((response) => {
                reject(response);
            });
    });
};

// Send the actual http request
api.sendRequest = (method, url, headers, data, optionals) => {
    return new Promise((resolve, reject) => {
        let options = {
            method: method,
            url: url,
            headers: headers,
            ...optionals
        };
        if (method == "GET") options.params = data;
        else options.data = data;

        axios(options)
            .then((response) => {
                resolve(response);
            })
            .catch(({ response }) => {
                resolve(response);
            });
    });
};

export default api
