import { ReactComponent as InfoTooltip } from "../assets/images/info.svg";
import { profileChange } from "../context/actions";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch } from "../context";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { useAuthState } from "../context";
import Layout from "../components/Layout";
import lang from "../lang";
import api from "../api";

export default function Profile() {
  const { userDetails, language, token } = useAuthState();
  const [userProfile, setUserProfile] = useState(userDetails);
  const [profileConfig, setProfileConfig] = useState(false);
  const [profileQuestions, setProfileQuestions] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useAuthDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (userDetails) {
      api
        .get("getProfileOptions", { user_id: userDetails.id })
        .then((response) => {
          if (response.data) {
            const configs = response.data.config;
            const questions = response.data.questions;
            const age = JSON.parse(getConfigByKey(configs, "age").config_value)
            const education = JSON.parse(
              getConfigByKey(configs, "education").config_value
            );
            const medication = JSON.parse(
              getConfigByKey(configs, "medication").config_value
            );
            const cultural_background = JSON.parse(
              getConfigByKey(configs, "cultural_background").config_value
            );

            setProfileConfig({
              age: age,
              education: education,
              medication: medication,
              cultural_background: cultural_background,
            });

            setProfileQuestions(questions);
          }
        });
      setUserProfile(userDetails)
    }
  }, []);

  // cand se schimba profilul utilizatorului, verific daca a completat toate campurile
  useEffect(() => {
    if (
      userProfile.gender &&
      userProfile.age &&
      userProfile.education &&
      userProfile.education !== "0" &&
      userProfile.cultural_background &&
      userProfile.cultural_background !== "0" &&
      userProfile.questions.length === profileQuestions.length
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [userProfile]);

  // cand actualizez intrebarile, setez valorile si in user profile
  useEffect(() => {
    if (profileQuestions) {
      setUserProfile({
        ...userProfile,
        questions: profileQuestions
          .filter((item) => item.answear > -1)
          .map((item) => {
            return { question_id: item.id, answear: item.answear };
          }),
      });
    }
  }, [profileQuestions]);

  const getConfigByKey = (object, key) => {
    return object.filter((item) => item.config_key === key)[0] || "";
  };

  const handleInputChange = (e) => {
    const { id, value, name, checked } = e.target;

    if (id === "male" || id === "female") {
      setUserProfile({ ...userProfile, gender: value });
    }
    else if (name.indexOf('medication') > -1) {
      let med = userProfile.medication ? userProfile.medication : []
      if (checked) {
        med.push(parseInt(value));
        setUserProfile({ ...userProfile, medication: med });

      } else {
        let newMed = med.filter(item => item !== parseInt(value))
        setUserProfile({ ...userProfile, medication: newMed });
      }
    }
    else {
      setUserProfile({ ...userProfile, [name]: value });
    }
  };

  const setQuestion = (item, e) => {
    const { value } = e.target;
    setProfileQuestions((prevValue) => {
      return prevValue.map((item2) => {
        if (item2.id === item.id) {
          return { ...item2, answear: parseInt(value) };
        } else {
          return item2;
        }
      });
    });
  };

  const goToNextStep = () => {
    if (!isComplete) {
      setShowWarning(true);
      setTimeout(() => {
        setShowWarning(false);
      }, 5000);
    } else {
      // apelez api
      api.post("editProfile", { user: userProfile }).then((response) => {
        profileChange(dispatch, { user: response.data });
        navigate('/questionnaires')
      });
    }
  };

  return (
    <Layout header={true}>
      <div className="container">
        <div className="page-content">
          <Sidebar />
          <div className="content">
            <div className="content-header">
              <h3>{lang.get('complete-profile')}</h3>
              <p>{lang.get('start-here')}</p>
            </div>

            <div className="content-gender">
              <p>{lang.get("gender")}</p>
              <div
                className={`radio-btn ${userProfile.gender === "male" ? "is-active" : ""
                  }`}
              >
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked={userProfile.gender === "male"}
                  onChange={(e) => handleInputChange(e)}
                />
                <label htmlFor="male">{lang.get('male')}</label>
              </div>
              <div
                className={`radio-btn ${userProfile.gender === "female" ? "is-active" : ""
                  }`}
              >
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked={userProfile.gender === "female"}
                  onChange={(e) => handleInputChange(e)}
                />

                <label htmlFor="female">{lang.get('female')}</label>
              </div>
            </div>

            <div className="content-age">
              <p>{lang.get("select-age")}</p>
              <select
                name="age"
                id="age"
                onChange={(e) => handleInputChange(e)}
                value={userProfile?.age}
              >
                <option value="0">{lang.get('age')}</option>
                {profileConfig &&
                  profileConfig?.age.map((item, index) => (
                    <option value={item.value} key={`age` + index}>
                      {language === "nl" ? item.label_nl : item.label_en}
                    </option>
                  ))}
              </select>
            </div>

            <div className="content-education">
              <p>{lang.get("select-education")}</p>
              <select
                name="education"
                id="education"
                onChange={(e) => handleInputChange(e)}
                value={userProfile?.education}
              >
                <option value="0">{lang.get('last-school')}</option>
                {profileConfig &&
                  profileConfig?.education.map((item, index) => (
                    <option key={"Education" + index} value={item.id}>
                      {language === "nl" ? item.value_nl : item.value_en}
                    </option>
                  ))}
              </select>
            </div>
            <div className="content_linie"></div>

            <div className="content-medication">
              <h5>{lang.get('use-medication')}</h5>
              <p>{lang.get('choose-all-apply')}</p>
              {profileConfig &&
                profileConfig.medication.map((item, index) => (
                  <div className={`checkbox-btn`} key={"Medication" + index}>
                    <input
                      type="checkbox"
                      id={item.value_en}
                      name={`medication${item.value_en}`}
                      value={item.id}
                      onChange={(e) => handleInputChange(e)}
                      checked={
                        userProfile?.medication &&
                        userProfile.medication.findIndex(item2 => item2 === parseInt(item.id)) > -1
                      }
                    />
                    <label htmlFor={item.value_en}>
                      {language === "nl" ? item.value_nl : item.value_en}
                    </label>
                  </div>
                ))}
            </div>

            <div className="content-cultural">
              <p>{lang.get('cultural-background')}</p>
              <select
                name="cultural_background"
                id="cultural_background"
                onChange={(e) => handleInputChange(e)}
                value={userProfile?.cultural_background}
              >
                <option value="0">{lang.get('select-cultural-background')}</option>
                {profileConfig &&
                  profileConfig?.cultural_background.map((item, index) => (
                    <option key={"cultural_background" + index} value={item.id}>
                      {language === "nl" ? item.value_nl : item.value_en}
                    </option>
                  ))}
              </select>
            </div>
            <div className="content_linie"></div>

            <div className="content-questions-container">
              {profileQuestions &&
                profileQuestions.map((item, index) => (
                  <div className="content-questions" key={"QuestionNr" + index}>
                    <>
                      <div className="content-questions-item1">
                        <p>{item.question[language]}</p>
                        {item.tooltip[language] && (
                          <span className="tooltip-info">
                            <span className="tooltip-info">
                              <InfoTooltip />
                              <div
                                className="tooltip-info_container"
                                style={{
                                  opacity: 0,
                                  pointerEvents: "none",
                                  transform: "translate(0,10px)",
                                }}
                              >
                                {item.tooltip[language]}
                              </div>
                            </span>
                            <div
                              className="tooltip-info_container"
                              style={{
                                opacity: 0,
                                pointerEvents: "none",
                                transform: "translate(0,10px)",
                              }}
                            >
                              {item.tooltip[language]}
                            </div>
                          </span>
                        )}
                      </div>
                      <div className="content-questions-item2">
                        <div
                          className={`radio-btn ${item.answear === 1 ? "is-active" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            name={`question${item.id}`}
                            id={`question${item.id}yes`}
                            value="1"
                            checked={item.answear === 1}
                            onChange={(e) => setQuestion(item, e)}
                          />
                          <label htmlFor={`question${item.id}yes`}>
                            {lang.get("yes")}
                          </label>
                        </div>
                        <div
                          className={`radio-btn ${item.answear === 0 ? "is-active" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            name={`question${item.id}`}
                            id={`question${item.id}no`}
                            value="0"
                            checked={item.answear === 0}
                            onChange={(e) => setQuestion(item, e)}
                          />
                          <label htmlFor={`question${item.id}no`}>
                            {lang.get("no")}
                          </label>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
            </div>

            <div className="content-buttons">
              {showWarning && (
                <div className="content-buttons_warning">
                  <p>{lang.get('fill-all-profile')}</p>
                  <img src="/images/warning.svg" alt="" />
                </div>
              )}

              <div className="content-buttons_container">
                {isComplete && (
                  <button
                    type="button"
                    className="open-popup content-buttons_container_btn container_btn-red mainBtn"
                    onClick={() => goToNextStep()}
                  >
                    <p>{lang.get('start-questionnaires')}</p>
                    <img src="/images/log-in-arrow.svg" alt="" />
                  </button>
                )}
                {!isComplete && (
                  <button
                    className="content-buttons_container_btn mainBtn"
                    onClick={() => goToNextStep()}
                  >
                    <p>{lang.get('start-questionnaires')}</p>
                    <img src="/images/log-in-arrow.svg" alt="" />
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}
