const Footer = () => {
    return (
        <div className="container">
            <footer className="footer">
                <p>{(new Date).getFullYear()} &copy; Mis en Mind. All rights reserved. </p>
            </footer>
        </div>
    )
}

export default Footer;