import React, { useEffect, useState } from 'react'

import { ReactComponent as DownloadLogo } from "../assets/images/download-logo.svg";
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Layout from '../components/Layout'
import { useAuthState } from '../context'
import reportHeader from '../assets/images/report-header.png'
import api from "../api";
import lang from "../lang";

const Report = () => {
    const { userDetails, surveysCompleted, language } = useAuthState()
    const navigate = useNavigate();
    const [sections, setSections] = useState([])
    const [defaultText, setDefaultText] = useState(false)
    const [user, setUser] = useState(false)

    useEffect(() => {
        if (!surveysCompleted) {
            return navigate('/questionnaires')
        } else {
            api.get('getReport', { user_id: userDetails.id }).then(response => {
                if (response.data.success) {
                    setSections(response.data.sections)
                    setDefaultText(response.data?.defaultText)
                    setUser(response.data?.user)
                } else {
                }
            })
        }
    }, [])

    const downloadPdf = () => {
        api.get('downloadReport', { user_id: userDetails.id, language: language }, { responseType: 'blob' }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Report-Mis-en-Mind.pdf');
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <>
            <Layout header={true}>
                <div className="page-content">
                    <Sidebar />
                    <div className="content">
                        <div className="report-container">
                            <h4 className="report-title">{lang.get('your-report')}</h4>
                            <div className="report-container_text dark-scrollbar">
                                <img src={reportHeader} />
                                <h1>{lang.get('report-title-line1')} <br />{lang.get('report-title-line2')}</h1>
                                {defaultText &&
                                    <div dangerouslySetInnerHTML={{ __html: language === 'nl' ? defaultText.value_nl : defaultText.value_en }}></div>}
                                {
                                    user &&
                                    <div className="profile-details">
                                        <p>{lang.get('your-profile-details')}</p>
                                        <table width="100%">
                                            <tr>
                                                <td>{lang.get("gender")}</td>
                                                <td>{lang.get(userDetails.gender)}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get("age")}</td>
                                                <td>{userDetails.age}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get("education")}</td>
                                                <td>{user.education[language]}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get("use-medication")}</td>
                                                <td>{user.medication ? user.medication[language].join(', ') : '-'}</td>
                                            </tr>
                                            <tr>
                                                <td>{lang.get("cultural-background")}</td>
                                                <td>{user.cultural_background[language]}</td>
                                            </tr>
                                        </table>
                                    </div>
                                }


                                {sections.map((section, index) => (
                                    <React.Fragment key={'section' + index}>
                                        <div className="title">{section?.title[language]}</div>
                                        <div dangerouslySetInnerHTML={{ __html: section.text[language] }}></div>
                                    </React.Fragment>
                                ))
                                }
                            </div>
                            <button className="report-btn" onClick={() => downloadPdf()}>
                                <DownloadLogo />
                                <p>{lang.get('download-report')}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Report