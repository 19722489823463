import { loginUser, useAuthState, useAuthDispatch } from '../context'
import { ReactComponent as Logo } from "../assets/images/logo.svg"
import AccountGroupImages from "../components/AccountGroupImages";
import React, { useEffect, useState } from "react";
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import Layout from "../components/Layout";
import Button from "../components/Button"
import lang from '../lang';
import Errors from '../components/Errors';

const Login = () => {
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({})

    const dispatch = useAuthDispatch()
    const { userDetails, token, language } = useAuthState()
    const isAuthenticated = Boolean(token)


    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
        if (Object.keys(errors).includes('email')) {
            hideError(false, 'email')
        }
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
        if (Object.keys(errors).includes('password')) {
            hideError(false, 'password')
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        let payload = { email, password }

        let response = await loginUser(dispatch, payload)
        if (response.success) {
            if (response.user) {
                const userr = response.user

                if (
                    userr.gender &&
                    userr.age &&
                    userr.education &&
                    userr.education !== "0" &&
                    userr.cultural_background &&
                    userr.cultural_background !== "0" &&
                    userr.questions.length
                ) {
                    return navigate('/questionnaires');
                }
            }
            navigate('/profile');

        } else {
            setErrors(response.errors);
        }
    };

    const hideError = (error = false, errKey = false) => {
        let keyy = false;
        if (!errKey) {
            keyy = Object.keys(errors).find(key => errors[key][0][language].toString().indexOf(error) > -1);
        } else {
            keyy = errKey;
        }
        let copy = { ...errors }
        delete copy[keyy]
        setErrors(copy)
    }


    useEffect(() => {
        if (isAuthenticated) {
            return navigate("/profile");
        }
    }, [])


    return (
        <Layout header={false}>
            <div className="login-container">
                <div className="form-container">
                    <div className="form-container_login">
                        <LanguageSwitcher />
                        <Logo className="logo center" />

                        <div className="form-btn-container">
                            <a className="btn login-btn active" href="/login">{lang.get('login')}</a>
                            <a className="btn new-user-btn" href="/register">{lang.get('new-user')}</a>
                        </div>

                        <div className="form-muted">
                            <p>{lang.get('login')}</p>
                            <span>{lang.get('returning-user')}</span>
                            <span>{lang.get('login-here')}</span>
                        </div>


                        <form className="form-cta-container" onSubmit={handleLogin}>
                            <div className="form-cta">
                                <label htmlFor="email">{lang.get('email-address')}</label>
                                <input onChange={onChangeEmail} type="email" name="email" id="email" placeholder={lang.get('email-address')} className={Object.keys(errors).includes('email') ? 'invalid' : ''} />
                            </div>
                            <div className="form-cta">
                                <label htmlFor="password">{lang.get('password')}</label>
                                <input onChange={onChangePassword} type="password" name="password" id="password" placeholder={lang.get('password')} className={Object.keys(errors).includes('password') ? 'invalid' : ''} />
                            </div>
                            <a className="forgot-password" href="/recover">{lang.get('forgot-password')}</a>
                            <Button text={lang.get('login')} color="#0b8275" type="submit" />
                        </form>

                        {Object.keys(errors).length > 0 && <Errors errors={errors} hideError={hideError} />}
                    </div>

                    <AccountGroupImages />
                </div>
            </div>
        </Layout>
    );
}

export default Login;