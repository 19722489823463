import { ReactComponent as PaymentGroup } from "../assets/images/payment-group.svg"
import { ReactComponent as Logo } from "../assets/images/logo.svg"
import AccountGroupImages from "../components/AccountGroupImages";
import { useSearchParams, useNavigate } from "react-router-dom";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { useEffect, useState } from "react";
import Button from "../components/Button";
import Layout from "../components/Layout";
import { useAuthState } from "../context";
import Errors from '../components/Errors'
import api from "../api";
import lang from "../lang";

const Register = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { language } = useAuthState()

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({})
    const [paymentStatus, setPaymentStatus] = useState(false)

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        api.post('auth/register', { email: email, language: language }).then(response => {
            if (response.data.redirect) {
                window.location.replace(response.data.redirect)
            } else {
                setErrors(response.data.errors)
            }
        })
    }

    useEffect(() => {
        if (searchParams.get('process_id')) {
            api.get('checkPaymentId', { id: searchParams.get('process_id') }).then(response => {
                if (response.data.success) {
                    if (response.data.status) {
                        setPaymentStatus(response.data.status);
                    }
                } else {
                    setPaymentStatus('unknown')
                    setErrors(response.data.error)
                }
            })
        }
    }, [])

    const hideError = (error = false, errKey = false) => {
        let keyy = false;
        if (!errKey) {
            keyy = Object.keys(errors).find(key => errors[key][0][language].toString().indexOf(error) > -1);
        } else {
            keyy = errKey;
        }
        let copy = { ...errors }
        delete copy[keyy]
        setErrors(copy)
    }

    return (
        <Layout header={false}>
            <div className="login-container">
                <div className="form-container">
                    <div className="form-container_login">
                        <LanguageSwitcher />
                        <Logo className="logo center" />

                        {!paymentStatus &&
                            <>
                                <div className="form-btn-container">
                                    <a className="btn login-btn" href="/login">{lang.get('login')}</a>
                                    <a className="btn new-user-btn active" href="/register">{lang.get('new-user')}</a>
                                </div>

                                <div className="form-muted">
                                    <p>{lang.get('create-account')}</p>
                                    <span>{lang.get('pay-online')}</span>
                                </div>


                                <form className="form-cta-container" onSubmit={handleRegister}>
                                    <div className="form-cta">
                                        <label htmlFor="email">{lang.get('email-address')}</label>
                                        <input type="email" name="email" id="email" placeholder={lang.get('email-address')} onChange={onChangeEmail} className={Object.keys(errors).includes('email') ? 'invalid' : ''} />
                                    </div>
                                    <div className="pay-card-img">
                                        <PaymentGroup />
                                    </div>

                                    <Button type="submit" text={lang.get('pay-now')} color="#0b8275" />
                                </form>
                            </>
                        }

                        {paymentStatus === 'initiated' &&
                            <>
                                <div className="form-muted mt50">
                                    <p>{lang.get('payment-processing')}</p>
                                    <span>{lang.get('payment-processing-info')}</span>
                                </div>
                                <Button type="submit" text={lang.get('go-to-login')} color="#0b8275" onClick={() => navigate('/login')} />
                            </>
                        }
                        {paymentStatus === 'paid' &&
                            <>
                                <div className="form-muted mt50">
                                    <p>{lang.get('payment-accepted')}</p>
                                    <span>{lang.get('payment-accepted-info')}</span>
                                </div>
                                <Button type="submit" text={lang.get('go-to-login')} color="#0b8275" onClick={() => navigate('/login')} />
                            </>
                        }
                        {paymentStatus && paymentStatus !== 'paid' && paymentStatus !== 'initiated' &&
                            <>
                                <div className="form-muted mt50">
                                    <p>{lang.get('payment-failed')}</p>
                                    <span>{lang.get('payment-failed-info')}</span>
                                </div>
                                <Button type="submit" text={lang.get('try-again')} color="#0b8275" onClick={() => { navigate('/register'); window.location.reload() }} />
                            </>
                        }
                        {Object.keys(errors).length > 0 && <Errors errors={errors} hideError={hideError} />}
                    </div>
                    <AccountGroupImages />
                </div>
            </div>
        </Layout>
    );
}

export default Register;