import api from "../api";
import axios from "axios";

export async function loginUser(dispatch, loginPayload) {
    const res = await api.post('auth/login', loginPayload).then(response => {
        if (response.data.success) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: { user: response.data.user, token: response.data.token, complete: response.data.surveysCompleted } })
            localStorage.setItem('auth', JSON.stringify({ user: response.data.user, token: response.data.token, surveysCompleted: response.data.surveysCompleted }))
            if (response.data.user && response.data.token) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
            } else {
                delete axios.defaults.headers.common['Authorization'];
            }
        }
        return response.data
    });

    return res;
}

export async function logoutUser(dispatch) {
    api.post('logout');
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('auth');
}

export function changeLanguage(dispatch, payload) {
    dispatch({ type: 'LANG_CHANGE', language: payload.language });
    localStorage.setItem('language', JSON.stringify(payload.language))

}
export function profileChange(dispatch, payload) {
    dispatch({ type: 'PROFILE_CHANGED', user: payload.user });
    let auth = localStorage.getItem('auth');
    if (auth) {
        auth = JSON.parse(auth);
        auth.user = payload.user;
        localStorage.setItem('auth', JSON.stringify(auth))
    }
}

export function changeProgress(dispatch, payload) {
    dispatch({ type: 'PROGRESS_CHANGED', complete: payload.complete });
    let auth = localStorage.getItem('auth');
    if (auth) {
        auth = JSON.parse(auth);
        auth.surveysCompleted = payload.complete;
        localStorage.setItem('auth', JSON.stringify(auth))
    }
}