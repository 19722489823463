import LocalizedStrings from 'react-localization';
import localTranslations from './translations.json';
import api from './api';

const lang = {
    defaultLanguage: 'nl',
    activeLang: 'nl',
    availableLangs: ['en', 'nl'],
    translations: null,
    multilanguage: null,

    init: async () => {
        try {
            await lang.updateTranslations();
            await lang.useSavedLanguage();
            await lang.loadRemoteTranslations();
        } catch (e) { console.log(e) }
    },

    updateTranslations: async (translations) => {
        if (!translations) translations = localTranslations;
        if (!lang.translations) lang.translations = {};

        Object.keys(translations).map(langCode => {
            if (!lang.translations.hasOwnProperty(langCode)) {
                lang.translations[langCode] = {};
            }
            translations[langCode] = { ...lang.translations[langCode], ...translations[langCode] };
        });
        lang.translations = translations;
        lang.multilanguage = new LocalizedStrings(lang.translations);
        lang.multilanguage.setLanguage(lang.activeLang);
    },

    loadRemoteTranslations() {
        return new Promise(resolve => {
            api.get('translations').then(response => {
                lang.updateTranslations(response.data);
                resolve();
            })
                .catch(err => {
                    resolve();
                })
        })
    },

    changeLang(newLang) {
        lang.activeLang = newLang;
        lang.multilanguage.setLanguage(lang.activeLang);
    },

    useSavedLanguage: async () => {
        let language = localStorage.getItem('language');
        if (language) {
            language = JSON.parse(language);
            if (lang.availableLangs.indexOf(language) !== -1) {
                lang.changeLang(language);
            }
        }
    },

    get(key, defaultText) {
        if (!lang.multilanguage) lang.init();
        let result = lang.multilanguage[key];
        // if (!result) lang.init();
        return result || defaultText;
    },

    translateModel(item, fields) {
        if (!fields) fields = [];
        let activeLang = lang.activeLang;
        fields.map(field => {
            if (!item.hasOwnProperty(field + '_t')) item[field + '_t'] = item[field];
            let fieldTranslations = item[field + '_t'];
            if (fieldTranslations.hasOwnProperty(activeLang) && fieldTranslations[activeLang]) {
                item[field] = fieldTranslations[activeLang];
            } else if (fieldTranslations.hasOwnProperty(lang.defaultLanguage) && fieldTranslations[lang.defaultLanguage]) {
                item[field] = fieldTranslations[lang.defaultLanguage];
            } else if (fieldTranslations.hasOwnProperty('nl') && fieldTranslations['nl']) {
                item[field] = fieldTranslations['nl'];
            } else if (fieldTranslations) {
                item[field] = '';
            } else {
                item[field] = '';
            }
        });
        return item;
    }
}

export default lang;
