import { useAuthState } from "../context"
import { useState } from "react"

const Errors = ({ errors, hideError }) => {
    const { language } = useAuthState()

    return (
        <>
            <div className="errors-container">
                {Object.values(errors).map((item, index) => (
                    <div className="error" key={`Error${index}`}>
                        <div>ERROR</div>
                        {item[0][language].toString()}
                        <span className="hide" onClick={() => hideError(item[0][language].toString())}>&#215;</span>
                    </div>
                ))
                }
            </div>
        </>
    )
}

export default Errors