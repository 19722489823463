import LogoGold from "../assets/images/logo-gold.png"
import LanguageSwitcher from "./LanguageSwitcher";
const Header = () => {
    return (
        <div className="container">
            <div className="header">
                <img src={LogoGold} className="logo" alt="LogoGold" />
                <LanguageSwitcher />
            </div>
        </div>
    );
}

export default Header;