
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Recover from "./pages/Recover";
import Profile from "./pages/Profile";
import Questionnaires from "./pages/Questionnaires";
import Report from "./pages/Report";
import RequireAuth from "./RequireAuth";
import { useEffect, useState } from "react";
import lang from "./lang";

export default function App() {
  const [translate, setTranslate] = useState(false)
  useEffect(() => {
    lang.init();
    lang.loadRemoteTranslations().then(res => setTranslate(true))
  }, [])

  return (
    <Routes>
      <Route exact path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/recover" element={<Recover />} />
      <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
      <Route path="/questionnaires" element={<RequireAuth><Questionnaires /></RequireAuth>} />
      <Route path="/report" element={<RequireAuth><Report /></RequireAuth>} />
    </Routes>
  );
}
