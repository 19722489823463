import lang from "../lang";
import { changeLanguage } from "../context/actions";
import { useAuthDispatch, useAuthState } from '../context'

const LanguageSwitcher = () => {
    const dispatch = useAuthDispatch();
    const { language } = useAuthState()
    const showLanguageSwitcher = true;

    return (
        showLanguageSwitcher ?
            <div className="language">
                <div className={language === 'en' ? 'active' : ''} onClick={() => { changeLanguage(dispatch, { language: "en" }); lang.changeLang("en") }}>EN</div>
                <div className="linie"></div>
                <div className={language === 'nl' ? 'active' : ''} onClick={() => { changeLanguage(dispatch, { language: "nl" }); lang.changeLang("nl") }}>NL</div>
            </div> : ''
    )
}

export default LanguageSwitcher