import AccountGroupImages from "../components/AccountGroupImages";
import { ReactComponent as Logo } from "../assets/images/logo.svg"
import LanguageSwitcher from "../components/LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Errors from "../components/Errors";
import Layout from "../components/Layout";
import { useAuthState } from "../context";
import { useState } from "react";
import lang from "../lang";
import api from "../api";

const Recover = () => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({})
    const { language } = useAuthState()
    const navigate = useNavigate();

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const handleRecover = async (e) => {
        e.preventDefault();
        api.post('auth/forgot-password', { email: email, language: language }).then(response => {
            if (response.data.success) {
                setSuccess(response.data.message)
                setTimeout(() => {
                    navigate('/login')
                }, 5000)
            } else {
                setErrors(response.data.errors);
            }
        })
    }

    const hideError = (error = false, errKey = false) => {
        let keyy = false;
        if (!errKey) {
            keyy = Object.keys(errors).find(key => errors[key][0][language].toString().indexOf(error) > -1);
        } else {
            keyy = errKey;
        }
        let copy = { ...errors }
        delete copy[keyy]
        setErrors(copy)
    }

    return (
        <Layout header={false}>
            <div className="login-container">
                <div className="form-container">
                    <div className="form-container_login">
                        <LanguageSwitcher />
                        <Logo className="logo center" />

                        <div className="form-muted">
                            <p>{lang.get('forgot-password-title')}</p>
                            <span>{lang.get('forgot-password-process')}</span>
                        </div>
                        <form className="form-cta-container" onSubmit={handleRecover}>
                            <div className="form-cta">
                                <label htmlFor="email">{lang.get('email-address')}</label>
                                <input type="email" name="email" id="email" placeholder={lang.get('email-address')} onChange={onChangeEmail} className={Object.keys(errors).includes('email') ? 'invalid' : ''} />
                            </div>
                            {Object.keys(errors).length > 0 && <Errors errors={errors} hideError={hideError} />}
                            {success &&
                                <div className="error success">
                                    {success}
                                </div>
                            }
                            <Button type="submit" text={lang.get('recover-password')} color="#0b8275" className="login-btn_primary recover-btn" />

                            <a className="forgot-password" href="/login">{lang.get('go-back-to-login')}</a>
                        </form>

                    </div>
                    <AccountGroupImages />
                </div>
            </div>
        </Layout>
    );
}

export default Recover;