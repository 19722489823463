import { ReactComponent as Check } from "../assets/images/check.svg"
import { useAuthState, useAuthDispatch } from '../context'
import { changeProgress } from '../context/actions';
import { useState, useRef } from 'react';
import Modal from 'react-modal';
import Button from './Button';
import lang from '../lang';
import api from '../api';

const Questionnaire = ({ questionnaire, number }) => {
    const { language, userDetails, surveysCompleted } = useAuthState();
    const [isActive, setIsActive] = useState(false)
    const [isComplete, setIsComplete] = useState(questionnaire?.isComplete || false)
    const [showQuestions, setShowQuestions] = useState(false)
    const [showTutorial, setShowTutorial] = useState(false)
    const [userResponse, setUserResponse] = useState([])
    const [errors, setErrors] = useState([])
    const dispatch = useAuthDispatch()

    const modalRef = useRef(null)

    const beginQuestionnaire = () => {

        if (questionnaire.video || questionnaire.text[language]) {
            setShowTutorial(true)
            setIsActive(false)
        } else {
            setIsActive(true)
            setShowQuestions(true)
        }
    }

    const closeQuestionnaire = () => {
        setIsActive(false)
        setShowQuestions(false);
        setShowTutorial(false);
        setUserResponse([])
        setErrors([])
    }

    const goToQuestions = () => {
        setShowQuestions(true);
        setShowTutorial(false);
        setIsActive(true)
    }

    const sendResponse = () => {
        const user_qids = [...new Set(userResponse.map(item => item.question))]
        const qids = questionnaire.questions.filter(item => item.all_answears.length > 0).map(item => item.id)

        if (JSON.stringify(qids.sort()) != JSON.stringify(user_qids.sort())) {
            setErrors([lang.get('complete-all-questions')])
        } else {
            api.post('completeQuestionnaire', {
                user_id: userDetails.id,
                questionnaire_id: questionnaire.id,
                responses: userResponse
            }).then(response => {
                if (response.data.success) {
                    setShowQuestions(false)
                    setIsComplete(true)
                    if (response.data.complete != surveysCompleted) {
                        changeProgress(dispatch, { complete: response.data.complete })
                    }
                } else {
                    setErrors([Object.values(response.data.errors)])
                }
            })
        }
    }

    const youtube_parser = (url) => {
        var ID = '';
        url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
        }
        else {
            ID = url;
        }
        return "https://www.youtube.com/embed/" + ID;
    }

    const handleAnswear = (e, question, multiple) => {
        const { value, checked } = e.target
        // daca intrebarea nu accepta raspuns multiplu, sterg sau adaug obiectele ce contin intrebarea curenta
        if (!multiple) {
            const newResponse = userResponse.filter(item => item.question != question)
            setUserResponse([...newResponse, { question: question, answear: parseInt(value) }])
        } else {
            // daca a bifat, adaug la array, altfel il sterg
            if (checked) {
                setUserResponse([...userResponse, { question: question, answear: parseInt(value) }])
            } else {
                const copy = [...userResponse]
                const index = copy.findIndex(item => item.question === question && item.answear === parseInt(value))
                copy.splice(index, 1)
                setUserResponse(copy)
            }
        }
    }

    const hideError = (error = false, errKey = false) => {
        const newError = errors.filter(item => item !== error)
        setErrors(newError)
    }

    // modal style
    const customStyles = {
        content: {
            right: 'auto',
            bottom: 'auto',
            maxWidth: '900px',
            width: '100%',
            background: 'none',
            border: '0px',
            borderRadius: '10px',
            padding: 0,
            inset: 'unset'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, .4)',
            zIndex: '22222',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    };

    return (
        <>
            {questionnaire.questions.length > 0 &&
                <div className="questionnaires-container">
                    <div className="questionnaires-item">
                        <div className={`check ${isComplete ? 'is-complete' : ''} `}>
                            <Check />
                        </div>
                    </div>

                    <div className="questionnaires-text">
                        <p className="no-selection">{questionnaire.title[language]}</p>
                        {!isComplete && <span onClick={beginQuestionnaire}>{lang.get('take-questionnaire')}</span>}
                    </div>
                </div>}


            {showTutorial &&
                <Modal isOpen={showTutorial} style={customStyles} shouldCloseOnOverlayClick={true} ariaHideApp={false} ref={modalRef}>
                    <div className="popup-box dark-scrollbar">
                        <div className="popup-box_header">
                            <h4>{questionnaire.title[language]}</h4>
                        </div>
                        {showTutorial &&
                            <div className="popup-box_item">
                                {questionnaire.video &&
                                    <div className="box-item_video">
                                        <iframe width="560" height="315" src={youtube_parser(questionnaire.video)}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                    </div>}
                                {questionnaire.text[language] &&
                                    <div className="box-item_text" dangerouslySetInnerHTML={{ __html: questionnaire.text[language] }}>
                                    </div>}

                                <div className="btn-container">
                                    <button className="popup-btn open-popup-2 mainBtn" onClick={goToQuestions}>
                                        {lang.get('go-to-questions')}
                                    </button>
                                    <div className="popup-btn-close" onClick={closeQuestionnaire}>{lang.get('cancel')}</div>
                                </div>
                            </div>}


                    </div>
                </Modal>}

            {isActive &&
                <Modal isOpen={isActive} style={customStyles} shouldCloseOnOverlayClick={true} ariaHideApp={false} ref={modalRef}>
                    <div className="popup-box dark-scrollbar">
                        <div className="popup-box_header">
                            <h4>{questionnaire.title[language]}</h4>
                            {showQuestions && <div className="popup-btn-close popup-btn-close-inline" onClick={closeQuestionnaire}>{lang.get('close-window')}</div>}
                        </div>
                        {
                            showQuestions &&
                            <div className="popup-box_item">
                                {questionnaire?.questions.map((question, index) => (
                                    <> {question.all_answears.length > 0 &&
                                        <>
                                            <div className="question-item" key={`questionn` + index}>
                                                <div className="question-item_text">
                                                    <h5 className="no-selection">{question.question[language]} {question.multiple ? <em> ({lang.get('multiple-answear')}) </em> : ''}</h5>
                                                </div>
                                                <div className="question-dropdown">
                                                    {question.extra_info[language] && <p>{question.extra_info[language]} </p>}
                                                    <div className="question-item_option_container">
                                                        {question?.all_answears.map((ans, index2) =>
                                                        (question.multiple ?
                                                            <div className={`checkbox-btn ${userResponse.some(item => item.question === question.id && item.answear === ans.id) ? 'is-active' : 'not-active'}`} key={`answear` + index2 + index}>
                                                                <input type="checkbox"
                                                                    id={`option${ans.id}`}
                                                                    name={`option${ans.id}`}
                                                                    value={ans.id}
                                                                    onChange={(e) => handleAnswear(e, question.id, question.multiple)} />
                                                                <label htmlFor={`option${ans.id}`}>{language === 'nl' ? ans.answear_nl : ans.answear_en}</label>
                                                            </div>
                                                            :
                                                            <div className={`no-selection radio-btn ${userResponse.find(item => item.question === question.id && item.answear === ans.id) ? 'is-active' : ''}`} key={`answear` + index2 + index}>
                                                                <input type="radio"
                                                                    id={`option${ans.id}`}
                                                                    name={`radio${question.id}`}
                                                                    value={ans.id}
                                                                    checked={userResponse.find(item => item.question === question.id && item.answear === ans.id) ? true : false}
                                                                    onChange={(e) => handleAnswear(e, question.id, question.multiple)} />
                                                                <label htmlFor={`option${ans.id}`}>{language === 'nl' ? ans.answear_nl : ans.answear_en}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content_linie"></div>
                                        </>
                                    }
                                    </>
                                ))
                                }

                                {Object.keys(errors).length > 0 &&
                                    <div className="errors-container">
                                        {Object.values(errors).map((item, index) => (
                                            <div className="error" key={`Error${index}`}>
                                                <div>ERROR</div>
                                                {item.toString()}
                                                <span className="hide" onClick={() => hideError(item.toString())}>&#215;</span>
                                            </div>
                                        ))
                                        }
                                    </div>}

                                <div className="btn-container">
                                    <Button text={lang.get('save-answears')} onClick={sendResponse}></Button>
                                </div>
                            </div>
                        }


                        {isComplete &&
                            <div className="popup-box_item">
                                <div className="popup-box_questions">
                                    <div className="save-questions-container">
                                        <div className="save-questions">
                                            <p>{lang.get('thank-you')} <br /><b>"{questionnaire.title[language]}"</b>.</p>
                                            <p>{lang.get('can-close-window')}</p>
                                        </div>
                                        <div className="popup-btn-close" onClick={closeQuestionnaire}>{lang.get('close-window-2')}</div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </Modal>}
        </>
    )
}

export default Questionnaire;