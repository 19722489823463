import Header from "./Header"
import Footer from "./Footer"
import "../assets/styles/style.css"
import "../assets/styles/responsive.css"

const Layout = (props) => {
  return (
    <>
      {props.header && <Header />}
      {props.children}
      <Footer />
    </>
  )
};

export default Layout;